/* ==============================================================
Bootstrap tweaks
===============================================================*/

body,
h1,
h2,
h3,
h4 {
  font-weight: 300;
}

* {
  font-family: Inter, serif !important;
}

body {
  margin: 0;
  font-family: Inter, serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  -ms-overflow-style: none !important;
  scrollbar-width: none !important;

  &::-webkit-scrollbar {
    display: none !important;
  }
}

a {
  color: #533f03;
  font-weight: bold;
}

a:hover {
  color: #533f03;
  /* make sure browsers use the pointer cursor for anchors, even with no href */
  cursor: pointer;
}

.dropdown-item:hover,
.dropdown-item:focus {
  color: white;
  text-decoration: none;
  background-color: #373a3c;
}

/* ==========================================================================
Browser Upgrade Prompt
========================================================================== */
.browserupgrade {
  margin: 0.2em 0;
  background: #ccc;
  color: #000;
  padding: 0.2em 0;
}

/* ==========================================================================
Main page styles
========================================================================== */

.hipster.img-fluid {
  display: inline-block;
  width: 347px;
  height: 497px;
  //background: url('../images/jhipster_family_member_3.svg') no-repeat center top;
  background-size: contain;
}

/* wait autoprefixer update to allow simple generation of high pixel density media query */
@media only screen and (-webkit-min-device-pixel-ratio: 2),
  only screen and (min--moz-device-pixel-ratio: 2),
  only screen and (-o-min-device-pixel-ratio: 2/1),
  only screen and (min-device-pixel-ratio: 2),
  only screen and (min-resolution: 192dpi),
  only screen and (min-resolution: 2dppx) {
  .hipster {
    //background: url('../images/jhipster_family_member_3.svg') no-repeat center top;
    background-size: contain;
  }
}

/* ==========================================================================
Generic styles
========================================================================== */

/* Error highlight on input fields */
.valid[required],
.valid.required {
  border-left: 5px solid green;
}

.invalid:not(form) {
  border-left: 5px solid red;
}

/* other generic styles */

.jh-card {
  padding: 1.5%;
  margin-top: 20px;
  border: none;
  background: inherit;
}

.error {
  color: white;
  background-color: red;
}

.pad {
  padding: 10px;
}

.w-40 {
  width: 40% !important;
}

.break {
  white-space: normal;
  word-break: break-all;
}

.readonly {
  background-color: #eee;
  opacity: 1;
}

.footer {
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}

.hand,
[jhisortby] {
  cursor: pointer;
}

/* ==========================================================================
Custom alerts for notification
========================================================================== */
.alerts {
  .alert {
    text-overflow: ellipsis;

    pre {
      background: none;
      border: none;
      font: inherit;
      color: inherit;
      padding: 0;
      margin: 0;
    }

    .popover pre {
      font-size: 10px;
    }
  }

  .toast {
    position: fixed;
    width: 100%;

    &.left {
      left: 5px;
    }

    &.right {
      right: 5px;
    }

    &.top {
      top: 55px;
    }

    &.bottom {
      bottom: 55px;
    }
  }
}

@media screen and (min-width: 480px) {
  .alerts .toast {
    width: 50%;
  }
}

/* ==========================================================================
entity tables helpers
========================================================================== */

/* Remove Bootstrap padding from the element
http://stackoverflow.com/questions/19562903/remove-padding-from-columns-in-bootstrap-3 */
@mixin no-padding($side) {
  @if $side == 'all' {
    .no-padding {
      padding: 0 !important;
    }
  } @else {
    .no-padding-#{$side} {
      padding-#{$side}: 0 !important;
    }
  }
}

@include no-padding('left');
@include no-padding('right');
@include no-padding('top');
@include no-padding('bottom');
@include no-padding('all');

/* bootstrap 3 input-group 100% width
http://stackoverflow.com/questions/23436430/bootstrap-3-input-group-100-width */
.width-min {
  width: 1% !important;
}

/* ==========================================================================
entity detail page css
========================================================================== */
.row.jh-entity-details {
  display: grid;
  grid-template-columns: auto 1fr;
  column-gap: 10px;
  line-height: 1.5;
}

@media screen and (min-width: 768px) {
  .row.jh-entity-details > {
    dt {
      float: left;
      overflow: hidden;
      clear: left;
      text-align: right;
      text-overflow: ellipsis;
      white-space: nowrap;
      padding: 0.5em 0;
    }

    dd {
      border-bottom: 1px solid #eee;
      padding: 0.5em 0;
      margin-left: 0;
    }
  }
}

@media screen and (min-width: 1264px) {
  .w-60 {
    width: 60% !important;
  }
}

/* ==========================================================================
ui bootstrap tweaks
========================================================================== */
.nav,
.pagination,
.carousel,
.card-title a {
  cursor: pointer;
}

.datetime-picker-dropdown > li.date-picker-menu div > table .btn-secondary,
.uib-datepicker-popup > li > div.uib-datepicker > table .btn-secondary {
  border: 0;
}

.datetime-picker-dropdown > li.date-picker-menu div > table:focus,
.uib-datepicker-popup > li > div.uib-datepicker > table:focus {
  outline: none;
}

.thread-dump-modal-lock {
  max-width: 450px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.full-width {
  width: 100%;
  display: block;
}

.mt-10vh {
  margin-top: 10vh;
}

.mt-11vh {
  margin-top: 11vh;
}

.number-input__input {
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
  border-radius: 0 !important;
  border-color: rgb(118, 118, 118) !important;
  background-color: transparent !important;

  &:focus {
    border-color: #147c78 !important;
  }
}

.text-16 {
  font-size: 1rem !important;
}

.w-100 {
  width: 100%;
}

.border-1 {
  border: 1px solid floralwhite !important;
}

/* jhipster-needle-scss-add-main JHipster will add new css style */

.no-arrows {
  input {
    -moz-appearance: textfield;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
}

.required-blue fieldset {
  border: 2px solid #1c7c78 !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

.v-text-field {
  border-radius: 12px !important;

  &--outlined {
    color: #4a4f4e !important;
  }
}

.v-toolbar__content {
  height: auto !important;
  padding: 0 !important;
  margin: 0 !important;
}

.font-14 {
  font-size: 14px !important;
}

.font-12 {
  font-size: 12px !important;
}

.font-24 {
  font-size: 24px !important;
}

.font-20 {
  font-size: 20px !important;
}

.font-16 {
  font-size: 16px !important;
}

.v-dialog::-webkit-scrollbar {
  display: none !important;
}

.v-dialog {
  -ms-overflow-style: none !important;
  scrollbar-width: none !important;
}

.removeScrollBar {
  -ms-overflow-style: none !important;
  scrollbar-width: none !important;

  *::-webkit-scrollbar {
    display: none !important;
  }

  * {
    -ms-overflow-style: none !important;
    scrollbar-width: none !important;
  }
}

.v-btn {
  border-radius: 12px !important;
  padding: 20px 15px !important;

  &--outlined {
    border: 1px solid #eaecf0;
    color: #4a4f4e !important;
  }
}

thead {
  tr {
    background-color: transparent !important;
  }

  th {
    box-shadow: none !important;
    -webkit-box-shadow: none !important;
    background-color: #f0f7f7 !important;
    text-wrap: nowrap;

    &:first-child {
      border-radius: 10px 0 0 10px !important;
    }

    &:last-child {
      border-radius: 0 10px 10px 0 !important;
    }
  }

  * {
    font-size: 15px !important;
  }
}

.v-date-picker-table {
  height: auto !important;
}

.v-data-footer {
  border-top: none !important;
}

.v-date-picker-table--date {
  th {
    padding: 0 !important;
  }
}

.custom-active {
  .v-list-item--active {
    border-radius: 10px;
    width: 80%;
    transform: translateX(10%);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
}

.v-list-item--active {
  color: #1c7c78 !important;
  background-color: #ebf3f3;
  border-radius: 10px;

  &::before {
    opacity: 0 !important;
  }
}

.text-h7 {
  font-size: 1rem;
  font-weight: 500;
}

fieldset {
  border: 1px solid #dedede;
}

.custom-append-icon {
  .v-input__append-inner {
    width: 60px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 0 !important;
    margin: 0 !important;
    border-left: 1px solid #dbe9e9;
    background-color: #f9fbfb;
    border-radius: 0 12px 12px 0 !important;
    color: #a0a19f !important;
  }

  .v-input__slot {
    padding: 0 0 0 12px !important;
  }
}

.v-input__slot {
  margin-bottom: 3px !important;

  .v-text-field__details {
    margin-bottom: 15px;
  }
}

.v-input__append-inner {
  display: flex;
  align-items: center;
  justify-content: center;
}

.custom-light-blue-box {
  border-radius: 10px;
  border: 1px solid #dbe9e9;
  background: #f0f7f7;
  color: #1c7c78;
  font-size: 16px;

  span {
    font-size: 20px;
    font-weight: 500;
  }
}

.custom-light-grey-box {
  border-radius: 10px;
  border: 1px solid #eaecf0;
  background: #fafafa;
  color: #4a4f4f;
  font-size: 16px;
}

.custom-light-red-box {
  border-radius: 10px;
  border: 1px solid #eaecf0;
  background: #fffbe7;
  color: #4a4f4f;
  font-size: 16px;
}

.flex-col-between {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.drawer-action-bar {
  position: absolute;
  right: 0;
  width: 100%;
}

.text-overflow-dotted {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.fill-height {
  height: 100%;
}

.custom-radio-button {
  border-radius: 10px !important;
  border: 1px solid #1c7c78 !important;
  background: #faffff !important;
  height: 35px !important;

  span {
    font-weight: 500 !important;
  }

  .v-icon {
    border: 5px solid;
    border-radius: 50%;
    width: 15px;
    height: 15px;
    color: #d7d7d7 !important;

    &::before {
      color: transparent;
    }
  }
}

.v-chip--active {
  .v-icon {
    color: #1c7c78 !important;
  }
}

.w-47 {
  width: 47% !important;
}

.w-90 {
  width: 90% !important;
}

.floating-tab {
  .v-slide-group__prev {
    display: none !important;
  }

  .v-tabs-bar {
    width: 99%;
    margin-left: 0.5%;
    height: 60px !important;
    border-radius: 10px;
    padding: 5px !important;
  }

  .v-tab--active {
    background-color: #1c7c78 !important;
    color: white !important;
    border-radius: 10px !important;
    border-bottom: none !important;
  }

  .v-tabs-slider {
    background-color: transparent !important;
  }
}

.custom-radius {
  .v-tabs-bar {
    border-radius: 10px 10px 0 0 !important;
  }
}

.radius-10 {
  border-radius: 10px !important;
}

.custom-line-between {
  height: 1px;
  width: 99.5%;
  background-color: #1c7c78;
  position: absolute;
  z-index: 1;
}

.z-index-up {
  z-index: 2;
}

.custom-panel {
  width: 99%;
  margin-left: 0.5%;
  border-radius: 10px;
  border: 1px solid #7685a1 !important;
  background: #f7f7f7 !important;
}

.no-upper {
  * {
    text-transform: none !important;
  }
}

.h-30 {
  height: 30% !important;
}

.primary--text {
  color: #1c7c78;
}

.custom-grid {
  height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.matrix-item {
  display: block;
  border-radius: 20px !important;
  min-width: 120px;
  max-width: 14.5vw;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.main-logo {
  width: 160px;
}

.light-blue-bg {
  background-color: #d5eaea;
}

.bottom-0 {
  bottom: 0 !important;
}

@media (min-width: 1264px) {
  .col-lg-15 {
    max-width: 14.28% !important;
  }
  .custom-grid {
    height: 83vh;
  }

  .matrix-item {
    max-width: 12vw;
  }
}

.text-white {
  color: white;
}

.text-black {
  color: black;
}

.field-grey {
  background-color: #fbfbfb !important;
  border-radius: 12px !important;

  .v-input__slot {
    margin: 0 !important;
  }
}

.no-upper {
  * {
    text-transform: none !important;
  }
}
