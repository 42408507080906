
.width-fit {
  width: fit-content !important;
}

.fit-height {
  height: fit-content !important;
}

.custom-app-bar {
  margin-left: 55px;
  height: 80px !important;
  background-color: white !important;
}

.border-bottom {
  border-bottom: 1px solid #f6f6f6 !important;
}

.floating-header {
  background-color: #f7f7f7;
  border-radius: 10px !important;
}

.v-toolbar__content {
  width: 100%;
}

.line-height-small {
  line-height: 1em;
}
