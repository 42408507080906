<template>
  <div class="pa-0 ma-0">
    <v-skeleton-loader type="card" v-if="downloading" :class="size + '-thumbnail-image'"></v-skeleton-loader>
    <img :src="imageSrc" alt="thumbnail" :class="size + '-thumbnail-image'" v-else-if="!isAvatar" />
    <img :src="imageSrc" alt="avatar" :class="size + '-thumbnail-image'" v-else-if="isAvatar && id != null" />
    <img src="../../../../content/images/no-image.png" alt="no image" :class="size + '-thumbnail-image'" v-else />
  </div>
</template>
<style>
.small-thumbnail-image {
  width: 32px !important;
  height: 32px !important;
  border-radius: 5px;
}
.large-thumbnail-image {
  width: 90px !important;
  height: 75px !important;
  border-radius: 5px;
}
.extra-large-thumbnail-image {
  width: 180px !important;
  height: 150px !important;
  border-radius: 5px;
}
</style>
<script src="./lazy-auth-image.component.ts"></script>
