<template>
  <v-col class="pa-0">
    <v-navigation-drawer
      v-model="drawer"
      :mini-variant.sync="mini"
      permanent
      expand-on-hover
      width="270px"
      class="removeScrollBar"
      :class="mini ? 'custom-active' : ''"
    >
      <v-list-item style="height: 80px">
        <v-list-item-icon>
          <v-icon class="me-3 mt-5" style="border-radius: 8px; border: 1px solid #f6f6f6; background: #fff"
            >{{ mini ? 'mdi-chevron-right' : 'mdi-chevron-left' }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-title><img src="../../../content/images/logo2.png" alt="logo" class="main-logo" width="711" /> </v-list-item-title>
      </v-list-item>

      <v-divider />
      <v-list dense v-if="isAdmin">
        <v-list-item>
          <v-list-item-icon>
            <v-icon>mdi-account-circle</v-icon>
          </v-list-item-icon>
          <v-list-item-content>{{ username }}</v-list-item-content>
        </v-list-item>
        <v-list-item to="/system-analyse">
          <v-list-item-icon>
            <v-icon>mdi-chart-areaspline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>System analyse</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item to="/estimate">
          <v-list-item-icon>
            <v-icon>mdi-information-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Estimating</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-card flat :color="dataGroupCardColor">
          <v-list-group v-model="systemParameterGroup" prepend-icon="mdi-tune">
            <template v-slot:activator>
              <v-list-item-title>System Parameter</v-list-item-title>
            </template>
            <v-list-item-group>
              <v-list-item to="/other-parameters">
                <v-list-item-icon>
                  <v-icon>mdi-list-status</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Andere Parameter</v-list-item-title>
              </v-list-item>
              <v-list-item to="/status">
                <v-list-item-icon>
                  <v-icon>mdi-list-status</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Status</v-list-item-title>
              </v-list-item>
              <v-list-item to="/digital-values">
                <v-list-item-icon>
                  <v-icon>mdi-weight</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Digitalwert</v-list-item-title>
              </v-list-item>
              <v-list-item to="/age">
                <v-list-item-icon>
                  <v-icon>mdi-tag-faces</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Altersgrenzen</v-list-item-title>
              </v-list-item>
              <v-list-item to="/life-expectancy">
                <v-list-item-icon>
                  <v-icon>mdi-account-heart</v-icon>
                </v-list-item-icon>
                <v-list-item-title>stat. Sterbetabelle</v-list-item-title>
              </v-list-item>
              <v-list-item to="/guaranteed-values">
                <v-list-item-icon>
                  <v-icon>mdi-home-thermometer</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Sofortrente Allianz</v-list-item-title>
              </v-list-item>
              <v-list-item to="/value-Declarer">
                <v-list-item-icon>
                  <v-icon>mdi-cash-multiple</v-icon>
                </v-list-item-icon>
                <v-list-item-title>ImmoWert Quelle Kunde</v-list-item-title>
              </v-list-item>
              <v-list-item to="/province">
                <v-list-item-icon>
                  <v-icon>mdi-map-marker-radius-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Grunderwerbssteuer</v-list-item-title>
              </v-list-item>
              <v-list-item to="/marital-status">
                <v-list-item-icon>
                  <v-icon>mdi-account-heart</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Familienstand</v-list-item-title>
              </v-list-item>
              <v-list-item to="/document-type">
                <v-list-item-icon>
                  <v-icon>mdi-file-document</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Unterlagentypen</v-list-item-title>
              </v-list-item>
              <v-list-item to="/customers-type">
                <v-list-item-icon>
                  <v-icon>mdi-account-multiple</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Kundentypen</v-list-item-title>
              </v-list-item>
              <v-list-item to="/offer-type">
                <v-list-item-icon>
                  <v-icon>mdi-circle</v-icon>
                </v-list-item-icon>
                <v-list-item-title> Angebotstypen</v-list-item-title>
              </v-list-item>
              <v-list-item to="/deviation">
                <v-list-item-icon>
                  <v-icon>mdi-circle</v-icon>
                </v-list-item-icon>
                <v-list-item-title> Modell Abweichungen</v-list-item-title>
              </v-list-item>
            </v-list-item-group>
          </v-list-group>
          <v-list-group v-model="systemSetting" prepend-icon="mdi-cog-outline">
            <template v-slot:activator>
              <v-list-item-title> Maklerbüro</v-list-item-title>
            </template>
            <v-list-item-group>
              <v-list-item to="/group-management">
                <v-list-item-icon>
                  <v-icon>mdi-account-multiple</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Maklerbüros</v-list-item-title>
              </v-list-item>
              <v-list-item to="/system-emails">
                <v-list-item-icon>
                  <v-icon>mdi-email-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-title>System Mail-Adressen</v-list-item-title>
              </v-list-item>
              <v-list-item to="/model-parameters">
                <v-list-item-icon>
                  <v-icon>mdi-wrench</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Modelle Parameter</v-list-item-title>
              </v-list-item>
              <v-list-item to="/lead-sources">
                <v-list-item-icon>
                  <v-icon>mdi-source-branch</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Quelle1</v-list-item-title>
              </v-list-item>
              <v-list-item to="/second-lead-sources">
                <v-list-item-icon>
                  <v-icon>mdi-source-branch</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Quelle2</v-list-item-title>
              </v-list-item>
              <v-list-item to="/marketing-actions">
                <v-list-item-icon>
                  <v-icon>mdi-arrow-expand-all</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Marketing Actions</v-list-item-title>
              </v-list-item>
              <v-list-item to="/sales-areas">
                <v-list-item-icon>
                  <v-icon>mdi-salesforce</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Sales Areas</v-list-item-title>
              </v-list-item>
              <v-list-item to="/chance-weight">
                <v-list-item-icon>
                  <v-icon>mdi-cash-100</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Estimating Levels</v-list-item-title>
              </v-list-item>
              <v-list-item to="/lead-generation-matrix">
                <v-list-item-icon>
                  <v-icon>mdi-matrix</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Leadgenerator matrix</v-list-item-title>
              </v-list-item>
              <v-list-item to="/direct-call">
                <v-list-item-icon>
                  <v-icon>mdi-account-voice</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Leadgenerator Direct Call</v-list-item-title>
              </v-list-item>
              <v-list-item to="/lead-parameters">
                <v-list-item-icon>
                  <v-icon>mdi-web</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>Leadgenerator Parameter</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item to="/email-black-lists">
                <v-list-item-icon>
                  <v-icon>mdi-email</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>VCO-Mailfilter</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item to="/property-features">
                <v-list-item-icon>
                  <v-icon>mdi-home-modern</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>Immobilienmerkmale</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list-group>
        </v-card>
        <v-card flat :color="dataGroupCardColor">
          <v-list-group v-model="marketerParameterGroup" prepend-icon="mdi-account-tie-voice">
            <template v-slot:activator>
              <v-list-item-title>Maklerbüroparameter</v-list-item-title>
            </template>
            <v-list-item-group>
              <v-list-item to="/default-display-value">
                <v-list-item-icon>
                  <v-icon>mdi-monitor</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Wertanzeige</v-list-item-title>
              </v-list-item>
              <v-list-item to="/model-a-parameter">
                <v-list-item-icon>
                  <v-icon>mdi-alpha-a-box</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Modell A</v-list-item-title>
              </v-list-item>
              <v-list-item to="/model-c-parameter">
                <v-list-item-icon>
                  <v-icon>mdi-alpha-c-box</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Modell C</v-list-item-title>
              </v-list-item>
              <v-list-item to="/model-d-parameter">
                <v-list-item-icon>
                  <v-icon>mdi-alpha-d-box</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Modell D</v-list-item-title>
              </v-list-item>
              <v-list-item to="/model-e-parameter">
                <v-list-item-icon>
                  <v-icon>mdi-alpha-e-box</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Modell E</v-list-item-title>
              </v-list-item>
              <v-list-item to="/model-f-parameter">
                <v-list-item-icon>
                  <v-icon>mdi-alpha-f-box</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Modell F</v-list-item-title>
              </v-list-item>
            </v-list-item-group>
          </v-list-group>
        </v-card>
      </v-list>
      <v-list dense v-if="!isAdmin">
        <v-list-item to="/profile">
          <v-list-item-icon>
            <v-icon>mdi-account-circle</v-icon>
          </v-list-item-icon>
          <v-list-item-content>{{ username }}</v-list-item-content>
        </v-list-item>
        <v-list-item to="/customer">
          <v-list-item-icon>
            <v-icon>mdi-account-multiple-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Kunden</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item to="/archived-customer" v-if="isGroupAdmin">
          <v-list-item-icon>
            <v-icon>mdi-account-off</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Archivierte Kunden</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item to="/calendar">
          <v-list-item-icon>
            <v-icon>mdi-calendar-text</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Kalender</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item to="/estimate" v-if="isGroupAdmin">
          <v-list-item-icon>
            <v-icon>mdi-information-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Estimating</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-card flat :color="dataGroupCardColor" v-if="isGroupAdmin">
          <v-list-group v-model="systemSetting" prepend-icon="mdi-cog-outline">
            <template v-slot:activator>
              <v-list-item-title> Maklerbüro</v-list-item-title>
            </template>
            <v-list-item-group>
              <v-list-item to="/status">
                <v-list-item-icon>
                  <v-icon>mdi-list-status</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Status</v-list-item-title>
              </v-list-item>
              <v-list-item to="/system-emails">
                <v-list-item-icon>
                  <v-icon>mdi-email-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-title>System Mail-Adressen</v-list-item-title>
              </v-list-item>
              <v-list-item to="/model-parameters">
                <v-list-item-icon>
                  <v-icon>mdi-wrench</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Modelle Parameter</v-list-item-title>
              </v-list-item>
              <v-list-item to="/notaries">
                <v-list-item-icon>
                  <v-icon>mdi-book-open-page-variant</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>Notare</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item to="/value-sources">
                <v-list-item-icon>
                  <v-icon>mdi-more</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>Quelle ImmoWert</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item to="/lead-sources">
                <v-list-item-icon>
                  <v-icon>mdi-source-branch</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Quelle1</v-list-item-title>
              </v-list-item>
              <v-list-item to="/second-lead-sources">
                <v-list-item-icon>
                  <v-icon>mdi-source-branch</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Quelle2</v-list-item-title>
              </v-list-item>
              <v-list-item to="/marketing-actions">
                <v-list-item-icon>
                  <v-icon>mdi-arrow-expand-all</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Marketing Quelle</v-list-item-title>
              </v-list-item>
              <v-list-item to="/sales-areas">
                <v-list-item-icon>
                  <v-icon>mdi-salesforce</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Sales Areas</v-list-item-title>
              </v-list-item>
              <v-list-item to="/user-management">
                <v-list-item-icon>
                  <v-icon>mdi-account-multiple</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Benutzerverwaltung</v-list-item-title>
              </v-list-item>
              <v-list-item to="/chance-weight">
                <v-list-item-icon>
                  <v-icon>mdi-cash-100</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Estimating Levels</v-list-item-title>
              </v-list-item>
              <v-list-item to="/lead-generation-matrix">
                <v-list-item-icon>
                  <v-icon>mdi-matrix</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Leadgenerator matrix</v-list-item-title>
              </v-list-item>
              <v-list-item to="/direct-call">
                <v-list-item-icon>
                  <v-icon>mdi-account-voice</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Leadgenerator Direct Call</v-list-item-title>
              </v-list-item>
              <v-list-item to="/lead-parameters">
                <v-list-item-icon>
                  <v-icon>mdi-web</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>Leadgenerator Parameter</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item to="/property-features">
                <v-list-item-icon>
                  <v-icon>mdi-home-modern</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>Immobilienmerkmale</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list-group>
        </v-card>
        <v-card flat :color="dataGroupCardColor">
          <v-list-group v-model="marketerParameterGroup" prepend-icon="mdi-account-tie-voice">
            <template v-slot:activator>
              <v-list-item-title>Berater parameter</v-list-item-title>
            </template>
            <v-list-item-group>
              <v-list-item to="/default-display-value">
                <v-list-item-icon>
                  <v-icon>mdi-monitor</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Wertanzeige</v-list-item-title>
              </v-list-item>
              <v-list-item to="/model-a-parameter">
                <v-list-item-icon>
                  <v-icon>mdi-alpha-a-box</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Modell A</v-list-item-title>
              </v-list-item>
              <v-list-item to="/model-c-parameter">
                <v-list-item-icon>
                  <v-icon>mdi-alpha-c-box</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Modell C</v-list-item-title>
              </v-list-item>
              <v-list-item to="/model-d-parameter">
                <v-list-item-icon>
                  <v-icon>mdi-alpha-d-box</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Modell D</v-list-item-title>
              </v-list-item>
              <v-list-item to="/model-e-parameter">
                <v-list-item-icon>
                  <v-icon>mdi-alpha-e-box</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Modell E</v-list-item-title>
              </v-list-item>
              <v-list-item to="/model-f-parameter">
                <v-list-item-icon>
                  <v-icon>mdi-alpha-f-box</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Modell F</v-list-item-title>
              </v-list-item>
            </v-list-item-group>
          </v-list-group>
        </v-card>
        <v-list-item to="/reset-all-settings" v-if="isGroupAdmin">
          <v-list-item-icon>
            <v-icon>mdi-reload</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Einstellungen zurücksetzen</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <template v-slot:append v-if="authenticated">
        <v-list-item @click="logout">
          <v-list-item-icon>
            <v-icon>mdi-logout</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <span>Ausloggen</span>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-navigation-drawer>
  </v-col>
</template>

<script lang="ts" src="./app-nav-drawer.component.ts"></script>
<style scoped>
.theme--light.v-navigation-drawer {
  position: fixed;
  z-index: 6;
  left: 0;
}

.theme--dark.v-navigation-drawer {
  position: fixed;
  z-index: 50;
  left: 0;
}

.v-btn::before {
  opacity: 0 !important;
}
</style>
