<template>
  <v-dialog v-model="promptDialog" persistent max-width="30rem" class="custom-dialog">
    <v-card class="radius-10" outlined elevation="0">
      <v-card-title class="tertiary py-3"> {{ promptDialogContent.title }} </v-card-title>
      <v-card-text class="pa-5 my-5">
        <p class="text-center ma-0">{{ promptDialogContent.text }}</p>
      </v-card-text>
      <v-card-actions>
        <v-row align="center" class="ma-0">
          <v-col class="col-6">
            <v-btn color="primary" @click="resolve" block> {{ promptDialogContent.resolve }}</v-btn>
          </v-col>
          <v-col class="col-6">
            <v-btn text @click="reject" outlined block> {{ promptDialogContent.reject }} </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style>
.custom-dialog {
  white-space: pre-line !important;
}
</style>

<script lang="ts" src="./prompt-dialog.component.ts"></script>
